import React from 'react';
import ArtDisplay from '../../components/ArtDisplay';
import './Art.css'

function Art() {
  return (
    <div>
        <h1>Welcome! <br></br>This is a repository containing the art that I have done throughout the years! <br></br>Hope you enjoy</h1>
    <ArtDisplay />
  </div>
  
  );
}
export default Art;
